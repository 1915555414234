import React, {Component} from 'react'
import Wrapper from '../components/Wrapper';
import PageHeader from "../components/PageHeader";
import PlacesToGo from "../components/PlacesToGo";
import {Col, Row} from "antd";
import Banner from "../components/Banner";
import {graphql} from "gatsby";

class PlacesToGoList extends Component {
    render() {

        const page = this.props.data.wordpressPage;
        const places = this.props.data.allWordpressWpPlace.edges.filter(({node}) => {
            return !node.hasOwnProperty('translations') || page.translations.current === node.translations.current;
        });;
        const acfFields = this.props.data.wordpressPage.acf;

        let seoTitle = acfFields.meta_title;
        let seoDescription = acfFields.meta_description;
        if (seoTitle===''){
            seoTitle = page.title;
        }

        let path = this.props.data.wordpressPage.slug;
        if (path === 'home') path = '';
        // add a trailing slash
        if (path.substr(-1) !== '/' && path !== '') path += '/';

        let slides = "";
        let headerImage = "";
        let depth = "";

        if(acfFields.header_images !== null && acfFields.header_images !== undefined) {

            if (acfFields.header_images.length > 1) {
                slides = [];
                acfFields.header_images.forEach(element =>
                    slides.push(element.localFile.childImageSharp.fluid)
                );
            }

            headerImage = acfFields.header_images[0].localFile?.childImageSharp?.fluid;
            depth = "full";

        } else {

            headerImage = this.props.data.allWordpressAcfOptions.edges[0].node.options.default_header_image.localFile?.childImageSharp?.fluid;
            depth = "thin";

        }

        return (
            <Wrapper
                title={seoTitle}
                description={seoDescription}
                og_title={acfFields.og_title}
                og_description={acfFields.og_description}
                og_image={acfFields.og_image ? acfFields.og_image.localFile.childImageSharp.original.src : null}
                twitter_card_type={acfFields.twitter_card_type}
                twitter_title={acfFields.twitter_title}
                twitter_description={acfFields.twitter_description}
                twitter_image={acfFields.twitter_image ? acfFields.twitter_image.localFile.childImageSharp.original.src : null}
                path={`/${path}`}
                locale={page.translations.current}
                translations={page.translations}
            >
                <PageHeader
                    title = {acfFields.display_header}
                    videoID = {acfFields.header_video_id}
                    image = {headerImage}
                    slides = {slides}
                    headerColor = {acfFields.header_colour}
                    headerStyle = {acfFields.header_style}
                    back = ""
                    date = ""
                    depth = {depth}
                />
                <div className="intro">
                    <Row gutter={70} type={"flex"}>
                        <Col xs={{span: 24}} md={{span: 20}} lg={{span: 18}}>
                            <p>{acfFields.introduction}</p>
                        </Col>
                    </Row>
                </div>
                <PlacesToGo places={places} locale={page.translations.current} hide=""/>
                <Banner
                    bgColor = {acfFields.banner_background_colour}
                    image = {acfFields.banner_image}
                    headerColor = {acfFields.banner_header_colour}
                    header = {acfFields.banner_header}
                    paraColor = {acfFields.banner_para_colour}
                    para = {acfFields.banner_paragraph}
                    btnLink = {acfFields.banner_button_link}
                    btnText = {acfFields.banner_button_text}
                    btnColor = {acfFields.banner_button_colour}
                />
            </Wrapper>
        )
    }
}

export const placeListQuery = graphql`
  query placeListQuery($id: String!) {
    wordpressPage(id: { eq: $id }) {
      id
      slug
      link
      wordpress_id
      wordpress_parent
      title
      translations {
        current
        en
        cy
      }
      acf {
        meta_title
        meta_description
		og_title
        og_description
        og_image {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }  
        }
        twitter_title
        twitter_card_type
        twitter_description
        twitter_image {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }  
        }
        
        header_style
        header_colour
        header_video_id
        header_images {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
                        fluid(quality: 80, maxWidth: 1440, maxHeight: 640) {
                            ... GatsbyImageSharpFluid
                        }
            }
          }
        }
        display_header
        show_back_link
        
        introduction
        
        banner_background_colour
        banner_image {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
                fluid(quality: 80) {
                  ... GatsbyImageSharpFluid
                }
            }
          }
        }
        banner_header_colour
        banner_para_colour
        banner_header
        banner_paragraph
        banner_button_colour
        banner_button_text
        banner_button_link
      }
    },
    allWordpressWpPlace {
      edges {
        node {
        id
        slug
        title
        date(formatString: "YYYY-MM-DDTHH:MM:SS")
        acf {
          image {
            localFile {
              childImageSharp {
                original {
                  src
                  width
                  height
                }
                fluid(maxWidth: 720, maxHeight:640, quality: 80, cropFocus: CENTER) {
                  ... GatsbyImageSharpFluid
                }
              }
            }    
          }
          excerpt
        }
        translations {
            current
            en
            cy
        }
      }
    }
  }
 }
`;

export default PlacesToGoList
