import React from 'react'
import './style.less';
import {Link} from "@reach/router";
import {LocaleContext} from "../../context/LocaleContext";
import language from "../../../data/language.yaml";

class Place extends React.Component {
    render() {

        return (
            <div className="place-item" style={{backgroundImage: "url(" + this.props.image?.src + ")"}}>
                <div className="place-content">
                    <h2 dangerouslySetInnerHTML={{__html: this.props.name}} />
                    <p>{this.props.blurb}</p>
                    <Link className="btn btn-primary" to={this.props.btnLink}>
                        <span>{language.explore[this.context.locale]}</span>
                        <span className="icon arrow"></span>
                    </Link>
                </div>
            </div>
        );
    }
}

Place.contextType = LocaleContext;

export default Place
